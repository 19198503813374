@import url(https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Nanum+Myeongjo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
:root{
  --sideMargin: 10px;
  --navCol: #101010;
  --navCol2: #cacaca;
  --navCol3: #8a8a8a;
  --titleFont: 'Bebas Neue', cursive;;
  --bodyFont: 'Nanum Gothic', sans-serif;
}

body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif;
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black; 
}

.backImg {
  z-index: -1;
  position: fixed;
};

.navBar{
  position: fixed;
  height: 39px;
  width: 100vw;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  color: var(--navCol2);
  border-bottom: solid 0.1px var(--navCol2);
  top:0%;
  z-index: 999;
}

.desktopNavBar{
  position: fixed;
  width: 100vw;
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--navCol);
  opacity: 0.85;
  z-index: 999;
}

.navList{
  display: flex;
  width: 76vw;
  justify-content: space-between;
}

.languageSelect{
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.languageSelectLinksL {
  border-right: solid 1px var(--navCol2);
  padding: 0px 2px;
  margin: 0;

}
.languageSelectLinksR {
  border-left: solid 1px var(--navCol2);
  padding: 0px 2px;
}

.navBar button{
  padding: 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  background-color:transparent;
  border: none;
  color: var(--navCol2);
  opacity: 0.95;
}

.desktopNavBar .navItem{
  width: 85px;
  padding: 0;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  background-color:transparent;
  border: none;
  color: var(--navCol2);
  opacity: 0.95;
}

.navBar a:hover{
  font-weight:600;
}

.mobileNavBar{
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: var(--navCol);
  color: var(--navCol2); 
  z-index: 999;
}

.mobileMenuTitle{
  height: 30px;
  width: calc(42vw - 10px);
  margin: 0;
  padding: 8px 0 0 10px;
  font-weight: 200;
  font-family: var(--titleFont);
  letter-spacing: 0.2em;
  font-size: 22px
}

.mobileMenuMidSection{
  width: 16vw;
}

.hamburger{
  width: 8vw;
  height: 39px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.hamburgerSq{
  margin-top: 5px;
  width: 8vw;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.hamburgerSq > * {
  width: 8vw;
  height: 2px;
  background-color: var(--navCol2);
}

.mobileLangContainer{
  width: 42vw;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.closeMenuSq{
  margin-top: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.mobileMenuItems{
  z-index: 999;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileMenuItems > *{
  margin: 7px 0px;
  width: 100vw;
  display: flex;
  justify-content: center;
} 

.mobileMenuItems > * > a{
  font-size: 1.4em;
  text-decoration:none;
  color: var(--navCol2);
}

.mobileMenuItems > * > a:visited, .mobileMenuItems > * > a:hover {
  color: var(--navCol2);
}

.filter{
  height: calc(100vh);
  width: 100vw;
  z-index: 1;
  position: fixed;
  background-image: linear-gradient(transparent, rgba(0,0,0,0.6));
}

@keyframes pageAnim {
  from {
    /* height: calc(101vh - 40px); */
    opacity: 0;
  }
  to {
    /* height: calc(65vh - 40px); */
    opacity: 1;
  }
}
.maincontent {
  z-index: 2;
  height: 100vh;
  /* margin-top: 40px; */
  position: absolute;
  overflow: scroll;
  /* border: solid red 2px; */
  overflow: scroll;
}
.mainContentM {
  z-index: 2;
  height: 100vh;
  position: absolute;
  overflow: scroll;
  /* border: solid red 2px; */
  overflow: scroll;
}

.pageContents{
  animation-name: pageAnim;
  animation-duration: 1.3s;
  animation-timing-function: ease-out;
}

.homeTitle {
  /* height: calc(100vh  - 39px - 10vh); */
  width: 100vw;
  margin: 0;
  padding: 0 0 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  /* border: solid 2px blue; */
  position: fixed;
  bottom: 10vh;
}

.homeTitle h1{
  color: var(--navCol2);
  font-family: var(--titleFont);
  font-weight: 800;
  margin: 0;
  padding: 0 0 0 0;
  opacity: 0.8;
  text-align: center;
  font-style: normal;
  font-weight: bold;
}




.pageTitle{
  height: calc(65vh);
  width: 100vw;
  margin: 0 0 0vh;
  padding: 0 0 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* animation-name: pageTitleAnim; */
  /* animation-duration: 1.3s; */
  /* animation-timing-function: ease-out; */
  opacity: 0.85;
}

.pageTitle h1{
  z-index: 2;
  color: white;
  font-family: var(--titleFont);
  font-weight: 800;
  margin: 0;
  padding: 0;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  line-height: 0.7em;
}

.pageContent{
  width: 100vw;
  min-height: calc(100vh - 65vh - 40px);
  background-color: var(--navCol);
  border-top: solid 0.1px var(--navCol3);
  opacity: 0.85; 
  box-shadow: 0px -2px 4vh 5vh rgba(30,30,30,0.4);
  /* animation-name: pageTitleAnim; */
  /* animation-duration: 1.3s; */
  /* animation-timing-function: ease-out; */
  /* border: 2px solid purple; */
}

/* SECTION - MOBILE */
.sectionMob{
  width: 90vw;
  margin: 0 5vw;
  padding: 1vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}
.sectionHeadingMob{
  color: var(--navCol2);
  font-family: var(--titleFont);
  letter-spacing: 0.1em;
  line-height: 0.8;
}
.sectionParagraphMob{
  color: var(--navCol2);
  font-family: var(--bodyFont);
  font-size: 1.2em;
  margin: 0;
  text-align: justify;
  line-height: 1.4em;
}
.sectionMob .secBLink{
  padding: 10px 0;
  text-align: center;
}
.sectionMob .secBLink a{
  color: var(--navCol2);
  font-family: var(--bodyFont);
  font-size: 1.2em;
  line-height: 1.4em;
}

/* SECTION - DESKTOP */
.section{ 
  width: 100vw;
  min-height: 2.8em;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 3vh;
}
.sectionHeading{
  width: calc(30vw - 3em);
  color: var(--navCol2);
  font-family: var(--titleFont);
  display: block;
  letter-spacing: 0.1em;
  font-size: 1.5em;
  margin: 0;
  padding: 0 1.5em;
}
.sectionHeading h2{
  margin: 0;
  padding: 0;
}
.sectionParagraph{
  width: calc(70vw - 2em);
  color: var(--navCol2);
  font-family: var(--bodyFont);
  font-size: 1.2em;
  margin: 0;
  padding: 0 1em;
  line-height: 1.6em;
}

.section .secBLink{
  padding: 10px 0;
}
.section .secBLink a{
  width: calc(70vw - 2em);
  color: var(--navCol2);
  font-family: var(--bodyFont);
  font-size: 1.2em;
  margin: 0;
  padding: 0 1em;
  line-height: 1.6em;
}

/* TEXT ONLY SECTION */
.textOnlyMob{
  width: 90vw;
  padding: 0px 0vh;
  font-size: 1.2em;
  margin: 0vh 5vw 0;
  text-align: center;
  color: var(--navCol2);
  display: flex;
  justify-content: center;
}
.textOnly{
  width: 90vw;
  padding: 0px 0vh;
  font-size: 1.2em;
  margin: 2vh 5vw 0;
  text-align: center;
  color: var(--navCol2);
  display: flex;
  justify-content: center;
}

/* SHOP BITS */
.shopContent{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.shopContent > .sqImgLink{
  width: 25vh;
  height: 25vh;
  margin: 2vh 4vw;
}
.shopContent > .sqImgLink > a > img{
  width: 25vh;
  height: 25vh;
  opacity: 1;
}

/* SOCIAL LINKS */
.socialLinks{
  width: 100%;
  height: 5vh;
  margin: 0 0 2vh;
  display: flex;
  justify-content: center;
  /* border: solid 2px yellow; */
  position: fixed;
  bottom: 2vh;
}
.socialLinksInline{
  width: 100%;
  height: 7vh;
  padding-top: 2vh;
  /* margin: 0 0 2vh; */
  display: flex;
  justify-content: center;
  /* border: solid 2px yellow; */
  position: relative;
  bottom: 2vh;
}
.socialLinks > .sqImgLink{
  width: 5vh;
  height: 5vh;
  margin: 0vw 4vw;
}
.socialLinksInline > .sqImgLink{
  width: 5vh;
  height: 5vh;
  margin: 0vw 4vw;
}
.socialLinks > .sqImgLink > a > img{
  width:5vh;
  height: 5vh;
  opacity: 0.8;
}
.socialLinksInline > .sqImgLink > a > img{
  width:5vh;
  height: 5vh;
  opacity: 0.8;
}


/* FOOTER BITS */
.creditHome{
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
.creditHome p{
  padding: 3px 5px;
  font-size: 0.65em;
  margin: 0;
  opacity: 0.6;
}
.creditHome p, .creditHome a{
  color: var(--navCol2);
}
.footer{
  width: 100vw;
  height: 40px;
  background-color: var(--navCol);
  margin: 0;
  padding: 0;
  opacity: 0.8; 
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  /* border: solid 2px rgb(128, 92, 98); */
}
.footer p {
  padding: 3px 5px;
  font-size: 0.65em;
  margin: 0;
  opacity: 0.6;
}
.footer p, .footer a{
  color: var(--navCol2);
}
.footerSec{
  width: 33vw;
}
.IconSocialLinks{
  width: 33vw;
  height: 40px;
  margin: 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 3px 0;
}
.IconSocialLinks > .iconLink{
  width: 20px;
  height: 20px;
  margin: 0vw 10px;
}
.IconSocialLinks > .iconLink > a > img{
  width: 20px;
  height: 20px;
  opacity: 0.85;
}
@media only screen and (max-width: 600px) {
.IconSocialLinks{
  width: 33vw;
  height: 30px;
  margin: 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 3px 0;
}
.IconSocialLinks > .iconLink{
  width: 15px;
  height: 15px;
  margin: 0vw 10px;
}
.IconSocialLinks > .iconLink > a > img{
  width: 15px;
  height: 15px;
  opacity: 0.85;
}
}


/* CONTACT FORM */


.gForm{
  width: 100vw;
  display: flex;
  justify-content: center;
  height: 780px;
  overflow: hidden;
}
